import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getMessaging, onMessage } from "firebase/messaging";
import { getStorage, ref } from "firebase/storage";

const fb_config = {
    apiKey: import.meta.env.VITE_API_KEY,
    authDomain: import.meta.env.VITE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_ID,
    measurementId: import.meta.env.VITE_MEASUREMENT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_DEFAULT_BUCKET
};

const app = initializeApp(fb_config);
let messaging = null;
const db = getFirestore(app);

try {
    if (window.location.protocol === "https:") {
        messaging = getMessaging(app);
        
        onMessage(messaging, (payload) => {
            if ("Notification" in window) {
                Notification.requestPermission().then((permission) => {
                    if (permission === "granted") {
                        var notification = new Notification(payload.data.title, {
                            body: payload.data.body,
                            image: payload.data.image,
                            icon: payload.data.icon || '/app-assets/images/logo.png',
                            requireInteraction: false,
                            silent: false,
                            vibrate: [200, 100, 200, 100, 200, 100, 200],
                        });
                        notification.onclick = function() {
                            window.open(payload.data.route);
                        };
                    }
                });
            }
        });
    } else {
        console.warn("Firebase Messaging requires HTTPS. Initialization skipped.");
    }
} catch (error) {
    console.error("Error initializing Firebase services:", error);
}

async function registerServiceWorker() {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
            // if (registration.active) {
            //     registration.active.postMessage(fb_config);
            //     console.log("📩 Firebase config sent to Service Worker");
            // } else {
            //     registration.addEventListener("statechange", () => {
            //         if (registration.active) {
            //             registration.active.postMessage(fb_config);
            //             console.log("Firebase config sent to Service Worker after activation");
            //         }
            //     });
            // }

            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            console.log("New update available.");
                            window.location.reload();
                        } else console.log("Content available offline.");
                    }
                };
            };
        } catch (error) {
            console.error("Service Worker registration failed:", error);
        }
    }
}

registerServiceWorker();
const storage = getStorage();

export default {messaging, db, storage, ref}